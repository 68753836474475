<template>
  <!--  <div id="nav">-->
  <!--    <router-link to="{ name: 'Home' }">Home</router-link> -->
  <!--  </div>-->
  <nav role="navigation" class="h-0">
    <div id="menuToggle">
      <input type="checkbox" />

      <span></span>
      <span></span>
      <span></span>

      <ul id="menu" class="bg-red-800 h-full">
        <a href="#what-we-do" title="What We Do"><li class="list-none">What We Do</li></a>
        <a href="#our-services" title="Our Services"><li class="list-none">Our Services</li></a>
        <a href="#contact-us" title="Contact Us"><li class="list-none">Contact Us</li></a>
      </ul>
    </div>
  </nav>
  <router-view />
  <footer class="h-10 p-2 px-4 w-full text-white bg-red-800 flex items-center justify-end">
    &copy; Benfield & Pyman Promotions {{ new Date().getFullYear() }}
  </footer>
</template>

<style>
#menuToggle
{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px;
  background-color: #991B1B;
  width: 65px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #fff;

  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: #ffffff;
}


#menuToggle input
{
  display: block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #ffffff;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #fff;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 300px;
  left: 0;
  margin: -100px 0 0 0;
  padding-top: 125px;
  height: calc(100vh + 100px);

  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 50px;
  font-size: 22px;
}

#menu li:hover {
  background-color: #7F1D1D;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}
</style>
