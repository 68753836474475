<template>
  <div class="w-full h-64 md:h-96 lg:h-128 xl:h-140">
    <div class="hero-placeholder w-full h-full"></div>
  </div>

  <section id="what-we-do" class="what-we-do md:h-screen overflow-hidden">
    <div class="snap-container w-full h-full grid grid-cols-1 md:grid-cols-2">
      <div class="boxer-placeholder w-full h-64 md:h-full"></div>
      <div class="p-8 bg-red-800 flex flex-col justify-center box-shadow">
        <h1 class="text-5xl mb-12">What we do</h1>
        <p class="my-8">
          Benfield and Pyman Promotions is a family run event organisation. We
          specialise in the promotion and development of sporting events;
          specifically, white collar boxing. Mike Pyman, one of the directors, has
          fought personally on a number of events across the UK in both boxing and
          mixed martial arts. Shaun Benfield, also a director, has an MBA and is
          an avid fan of combat sports.
        </p>

        <p class="my-8">
          In the sector of white-collar boxing; we pride ourselves in providing
          safe, exciting and inclusive events for all. We have SIA certified
          security at all of our events, as well as three members of medical
          staff. The safety of our fighters and spectators is paramount. As we
          look to create our own league within the sport we are always on the
          lookout for new fighters. If this is of interest to you, then please
          contact us!
        </p>

        <p class="my-8">
          We believe in sponsoring those athletes that display our ethos. We have
          recently sponsored Ryan Wheeler, a professional athlete who will be
          fighting for the WBO European Super-Featherweight title in Kazakhstan on
          11th December 2021. We wish Ryan the best of luck and the greatest of
          success as he climbs the professional ranks.
        </p>
      </div>
    </div>
  </section>

  <section id="our-services" class="our-services h-auto md:h-screen overflow-hidden relative">
    <div class="snap-container w-full h-full grid grid-cols-2">
    <div class="p-8 bg-red-800 flex flex-col justify-center box-shadow relative z-2">
      <h1 class="text-5xl mb-12">Our Services</h1>

      <p class="my-8">
        We are currently focussing all our attention on white-collar boxing. We
        do, however, have a vested interest in providing the following in the
        future:
      </p>

        <ul class="my-8">
        <li class="my-4 list-disc">White-collar boxing events</li>
        <li class="my-4 list-disc">Mixed Martial Arts events</li>
        <li class="my-4 list-disc">Security for events</li>
        <li class="my-4 list-disc">Medical cover for events</li>
        <li class="my-4 list-disc">Boxing ring/octagon hire</li>
      </ul>
    </div>

      <div class="services-placeholder w-full h-full z-1"></div>
    </div>
  </section>

  <section id="contact-us" class="contact-us flex flex-col justify-center items-center">
    <h1 class="col-span-5 text-5xl text-center m-16">Want to know more?</h1>
    <div class="w-full flex flex-col lg-flex-row justify-evenly p-4">
      <div class="facebook text-white">
        <a href="https://www.facebook.com/Benfieldandpymanpromotions/" title="Benfield & Pyman Promotions on Facebook">
          <div class="flex flex-col justify-center items-center p-4 text-lg hover:bg-gray-700 hover:shadow-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-facebook w-20 h-20 mb-8" viewBox="0 0 16 16">
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
            Follow us on Facebook
          </div>
        </a>
      </div>

      <div class="instagram text-white">
        <a href="https://www.instagram.com/benfieldandpymanpromotions2021/" title="Benfield & Pyman Promotions on Instagram">
          <div class="flex flex-col justify-center items-center p-4 text-lg hover:bg-gray-700 hover:shadow-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-instagram w-20 h-20 mb-8" viewBox="0 0 16 16">
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
            Follow us on Instagram
          </div>
        </a>
      </div>

      <div class="email text-white">
        <a href="mailto:benfieldandpymanpromotions@gmail.com" title="Benfield & Pyman Promotions on Instagram">
          <div class="flex flex-col justify-center items-center p-4 text-lg hover:bg-gray-700 hover:shadow-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-envelope-fill w-20 h-20 mb-8" viewBox="0 0 16 16">
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
            </svg>
            Email us:<br>benfieldandpymanpromotions@gmail.com
          </div>
        </a>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "Home",
  meta: {
    title: "Benfield & Pyman Promotions",
    metaTags: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Benfield & Promotions specialise in the promotion & development of sporting events. We pride ourselves in providing safe, exciting, inclusive events.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>

<style scoped>
p,
h1,
li {
  color: #fff;
}

.contact-us,
.what-we-do,
.our-services,
.hero {
  scroll-snap-type: y mandatory;
}

.contact-us > .snap-container,
.what-we-do > .snap-container,
.our-services > .snap-container,
.hero > .snap-container {
  scroll-snap-align: start;
}

.hero-placeholder {
  background-image: url("../assets/hero.jpg");
}

.boxer-placeholder {
  background-image: url("../assets/boxer.jpg");
}

.services-placeholder {
  background-image: url("../assets/services.jpg");
}

.services-placeholder, .boxer-placeholder, .hero-placeholder {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.box-shadow {
  box-shadow: 0 0 25px 10px #000;
}

</style>
